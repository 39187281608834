import { IconProps } from '@/types/common';

const SlackIcon = ({ height = 24, width = 24, className }: IconProps) => {
  return (
    <svg viewBox="0 0 128 128" width={width} height={height} className={className}>
      <path d="M27.15 80.766c0 7.351-5.994 13.355-13.345 13.355C6.454 94.12.45 88.117.45 80.766c0-7.351 6.004-13.355 13.355-13.355H27.15zm6.73 0c0-7.351 6.003-13.355 13.354-13.355 7.351 0 13.355 6.004 13.355 13.355v33.43c0 7.35-6.004 13.354-13.355 13.354-7.351 0-13.355-6.004-13.355-13.355v-33.43zM47.234 27.15c-7.351 0-13.355-5.994-13.355-13.345C33.88 6.454 39.883.45 47.234.45c7.351 0 13.355 6.004 13.355 13.355V27.15zm0 6.73c7.351 0 13.355 6.003 13.355 13.354 0 7.351-6.004 13.355-13.355 13.355h-33.43C6.455 60.589.45 54.585.45 47.234c0-7.351 6.004-13.355 13.355-13.355h33.43zm53.616 13.354c0-7.351 5.994-13.355 13.345-13.355 7.351 0 13.355 6.004 13.355 13.355 0 7.351-6.004 13.355-13.355 13.355H100.85zm-6.73 0c0 7.351-6.003 13.355-13.354 13.355-7.351 0-13.355-6.004-13.355-13.355v-33.43C67.411 6.455 73.415.45 80.766.45c7.351 0 13.355 6.004 13.355 13.355zM80.766 100.85c7.351 0 13.355 5.994 13.355 13.345 0 7.351-6.004 13.355-13.355 13.355-7.351 0-13.355-6.004-13.355-13.355V100.85zm0-6.73c-7.351 0-13.355-6.003-13.355-13.354 0-7.351 6.004-13.355 13.355-13.355h33.43c7.35 0 13.354 6.004 13.354 13.355 0 7.351-6.004 13.355-13.355 13.355zm0 0" />
    </svg>
  );
};

export default SlackIcon;
